import { Component, OnInit } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-my-company',
  templateUrl: './my-company.component.html',
  styleUrls: ['./my-company.component.css']
})
export class MyCompanyComponent implements OnInit {

  getMyCompany: Array<any> = [];
  constructor(
    private rsbService: RsbService,
    private rsbLookup: RsbLookupModel
  ) {
    console.log("mylead")
  }

  ngOnInit(): void {
    console.log("my lead")
    console.log(JSON.stringify(this.getMyCompany))
    console.log(this.rsbLookup.getLoggedUser())
    this.getMyleads()
  }

  getMyleads() {
    this.rsbService.getBuilderCompanies({}).subscribe((rsp) => {
      if (rsp.statusCode === 0 && rsp.contents) {
        this.getMyCompany = rsp.contents;
      } else {
        this.getMyCompany = []
      }
    })

  }
}
