import { Component, OnInit } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.css']
})
export class LeadsComponent implements OnInit {
  moduleList: Array<any> = [];
  allListings: Array<any> = [];
  tableListView: boolean = false;

  listings: any;
  getMyViewLead: any;

  constructor(private rsbservice: RsbService,
    private lookupModel: RsbLookupModel
  ) { }

  ngOnInit(): void {

      this.loadModuleList();
    setTimeout((obj) => {
      this.getMyViewLeads();
    }, 1000)
  }

  getMyViewLeads() {
    const selectedMainModuleId: Array<any> = this.moduleList.filter((obj) => {
      return obj.selected && obj.selected === true;
    })
    if (!selectedMainModuleId[0].moduleName.toUpperCase().includes('PG')) {
      this.rsbservice.myLeads({ "mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {

        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMyViewLead = rsp.contents;
        } else {
          this.getMyViewLead = []
        }
      })
    } else {
      this.rsbservice.myLeadsForPg({ "mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {

        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMyViewLead = rsp.contents;
        } else {
          this.getMyViewLead = []
        }
      })
    }
  }

  changeMainModule(module): void {

    this.moduleList.forEach((obj) => {
      if (module.mainModuleId === obj.mainModuleId) {
        obj.selected = true;
      } else {
        obj.selected = false;
      }
    })

    this.getMyViewLeads();
  }
  loadModuleList(): void {
    const vendorId = this.lookupModel.getLoggedUser().vendorTypeId;

    this.rsbservice.getAllMainModules({}).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        if (rsp.contents.length > 0) {

          this.moduleList = rsp.contents;
          this.moduleList = this.moduleList.filter((data) => {

            return data.vendorTypeId.includes(vendorId)
          })
          this.moduleList.forEach((obj) => {
            obj["selected"] = false
          })
          this.moduleList[0].selected = true;
          console.log(this.moduleList[0]);
        }
        else {
          this.moduleList = [];
        }
      }
    })


  }

}
