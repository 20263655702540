import { Component, OnInit, ElementRef, ViewChild, NgZone, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { MapsAPILoader } from '@agm/core';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-pg-location',
  templateUrl: './pg-location.component.html',
  styleUrls: ['./pg-location.component.css']
})
export class PgLocationComponent implements OnInit {
  @Output() showNextPage: EventEmitter<number> = new EventEmitter();
  @Output() formChange: EventEmitter<boolean> = new EventEmitter();
  locationForm: FormGroup;
  selectedProperty: any;
  selectedCountry: any;
  countryList: Array<any> = [];

  private cityText: ElementRef;
  @ViewChild('cityText') set cityEle(cityElement: ElementRef) {
    if (cityElement && this.lookupModel.isBrowser) {
      this.cityText = cityElement;
      this.prepareCityAutocomplete();
    }
  }
  private localityText: ElementRef;
  @ViewChild('localityText') set localityEle(lclElement: ElementRef) {
    if (lclElement) {
      this.localityText = lclElement;
    }
  }
  private landmarkText: ElementRef;
  @ViewChild('landmarkText') set landmarkEle(landmarkElement: ElementRef) {
    if (landmarkElement) {
      this.landmarkText = landmarkElement;
    }
  }

  lat: number;
  long: number;
  zoom: number = 4;
  cityBounds: any;
  changeCountryFlag: boolean = true;
  oldCountryValue: string;

  private geoCoder;
  landmarkAutoComplete: any;
  localityAutoComplete: any;
  cityAutoComplete: any;
  mapRestriction: any;
  loadView: boolean = false;
  isFormDirty: boolean = false;
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService
  ) {
   }

  ngOnInit(): void {
    this.selectedProperty = this.lookupModel.getVendorProperty();
    this.locationForm = this.fb.group({
      country: [this.selectedProperty ? this.selectedProperty.countryId : '', [Validators.required]],
      city: [this.selectedProperty ? this.selectedProperty.city : '', [Validators.required]],
      cityAuto: [this.selectedProperty ? this.selectedProperty.city : '', [Validators.required]],
      locality: [this.selectedProperty ? this.selectedProperty.area : '', [Validators.required]],
      localityAuto: [this.selectedProperty ? this.selectedProperty.area : '', [Validators.required]],
      addressLine1: [this.selectedProperty ? this.selectedProperty.addressLine1 : ''],
      landmark: [this.selectedProperty ? this.selectedProperty.landmark : ''],
      landmarkAuto: [this.selectedProperty ? this.selectedProperty.landmark : ''],
      pincode: [this.selectedProperty ? this.selectedProperty.pincode : '', [Validators.required,Validators.pattern('^[0-9]*$')]]
    });
    
    if (this.selectedProperty) {
      this.lat = this.selectedProperty.lattitude;
      this.long = this.selectedProperty.longitude;
      this.zoom = 12;
      if (this.selectedProperty.cityBoundries) {
        this.mapRestriction = {
          latLngBounds: this.selectedProperty.cityBoundries,
          strictBounds: true
        };
        this.cityBounds = this.selectedProperty.cityBoundries;
      }
    }

    this.registerFormChanges();
    this.getCountries();
    this.loadView = true;

    if(this.checkReadOnly()) {
      this.locationForm.disable({emitEvent: false});
    }
  }

  checkReadOnly(): boolean {
    const readonly: boolean = this.selectedProperty && ([1, 2, 3].includes(this.selectedProperty.status));
    return readonly;
  }

  registerFormChanges(): void {
    this.locationForm.valueChanges.subscribe((val) => {
      this.emitFormChanges(true);
    });

    this.country.valueChanges.subscribe((val) => {
      if (val !== this.oldCountryValue) {
        this.changeCountryFlag = false;
        this.oldCountryValue = val;
        const country = this.countryList.find((con) => con.codeId === val);
        if (country) {
          this.selectedCountry = country;
          this.lat = country.latitude;
          this.long = country.longitude;
          // this.geoCoder = {};
          this.lookupModel.setPropertyCountry(this.selectedCountry);
        }
        if(this.checkReadOnly()) {
          this.locationForm.disable({emitEvent: false});
        } else {
          this.locality.reset();
          this.localityAuto.reset();
          this.addressLine1.reset();
          this.landmark.reset();
          this.landmarkAuto.reset();
          this.pincode.reset();
          this.landmark.disable();
          this.locality.disable();
          this.country.disable();
        }
        // this.prepareCityAutocomplete();
      }
    });
  }

  getCountries(): void {
    this.countryList = this.lookupModel.getCountryList();
    let codeId = '';
    if (this.selectedProperty) {
      // codeId = this.selectedProperty.countryId;
      codeId = this.lookupModel.selectedCountry.codeId;



      const country = this.countryList.find((con) => con.codeId === codeId);
      this.selectedCountry = country;
      this.lookupModel.setPropertyCountry(this.selectedCountry);
      this.oldCountryValue = this.selectedProperty.countryId;
      this.changeCountryFlag = false;
      this.country.disable({emitEvent: false});
    } else {
      codeId = this.lookupModel.getCountry().codeId;
      // codeId = this.countryList[0].codeId;
      this.changeCountryFlag = false;
      this.country.disable();
    }
    if(codeId !== this.country.value) {
      this.country.setValue(codeId);
    }
    // this.selectCountry(codeId);
  }

  changeCountry(): void {
    this.changeCountryFlag = true;
    this.country.enable();
    this.localityAutoComplete = null;
    this.landmarkAutoComplete = null;
    this.cityBounds = {};
    this.mapRestriction = null;
    this.zoom = 5;
    this.city.reset();
    this.cityAuto.reset();
  }

  prepareCityAutocomplete(): void {
    if(this.checkReadOnly()) {
      return;
    }
    this.city.enable();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.cityAutoComplete = new google.maps.places.Autocomplete(this.cityText.nativeElement, {
        componentRestrictions: {
          country: this.selectedCountry.twoDigitCountryCode
        },
        types: ['(cities)'],
        //bounds: []
        strictBounds: true
      });
      this.cityAutoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = this.cityAutoComplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.mapRestriction = {
            latLngBounds: place.geometry.viewport,
            strictBounds: true
          };
          this.cityBounds = place.geometry.viewport;
          this.city.setValue(place.name);
          this.cityAuto.setValue(place.name);
          this.locality.reset();
          this.localityAuto.reset();
          this.addressLine1.reset();
          this.landmark.reset();
          this.landmarkAuto.reset();
          this.pincode.reset();
          const postalCode = place.address_components.find(comp => comp.types.indexOf('postal_code') !== -1);
          if (postalCode) {
            this.pincode.setValue(postalCode.long_name);
          }
          this.lat = place.geometry.location.lat();
          this.long = place.geometry.location.lng();
          this.zoom = 10;
          this.prepareLocalityAutocomplete();
        });
      });
    });
  }

  prepareLocalityAutocomplete(): void {
    if(this.checkReadOnly()) {
      this.locality.setValue(this.selectedProperty.area);
      this.localityAuto.setValue(this.selectedProperty.area);
      return;
    }
    if(this.selectedCountry.twoDigitCountryCode && this.cityBounds) {
      this.locality.enable();
      if (!this.localityAutoComplete) {
        this.localityAutoComplete = new google.maps.places.Autocomplete(this.localityText.nativeElement, {
          componentRestrictions: {
            country: this.selectedCountry.twoDigitCountryCode
          },
          bounds: this.cityBounds,
          strictBounds: true
        });
        this.localityAutoComplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = this.localityAutoComplete.getPlace();

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            this.mapRestriction = {
              latLngBounds: place.geometry.viewport,
              strictBounds: true
            };

            const postalCode = place.address_components.find(comp => comp.types.indexOf('postal_code') !== -1);
            if (postalCode) {
              this.pincode.setValue(postalCode.long_name);
            }
            this.locality.setValue(place.name);
            this.localityAuto.setValue(place.name);
            this.lat = place.geometry.location.lat();
            this.long = place.geometry.location.lng();
            this.zoom = 15;
            this.addressLine1.reset();
            this.landmark.reset();
            this.landmarkAuto.reset();
            this.prepareLandmarkAutocomplete();
          });
        });
      }
      else {
        this.localityAutoComplete.bounds = this.cityBounds;
      }
    }
  }

  prepareLandmarkAutocomplete(): void {
    this.landmark.enable();
    if (!this.landmarkAutoComplete) {
      this.landmarkAutoComplete = new google.maps.places.Autocomplete(this.landmarkText.nativeElement, {
        componentRestrictions: {
          country: this.selectedCountry.twoDigitCountryCode
        },
        bounds: this.mapRestriction.latLngBounds,
        strictBounds: true
      });
      this.landmarkAutoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = this.landmarkAutoComplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.mapRestriction = {
            latLngBounds: place.geometry.viewport,
            strictBounds: true
          };

          const postalCode = place.address_components.find(comp => comp.types.indexOf('postal_code') !== -1);
          if (postalCode) {
            this.pincode.setValue(postalCode.long_name);
          }
          this.landmark.setValue(place.name);
          this.landmarkAuto.setValue(place.name);

          this.lat = place.geometry.location.lat();
          this.long = place.geometry.location.lng();
          this.zoom = 15;
          // this.addressLine1.reset();
        });
      });
    }
    else {
      this.landmarkAutoComplete.bounds = this.mapRestriction.latLngBounds;
    }
  }

  markerDragEnd($event: any) {
    if(!this.checkReadOnly()) {
      this.lat = $event.latLng.lat();
      this.long = $event.latLng.lng();
      this.getAddress(this.lat, this.long);
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          const addressComponents = results[0].address_components
          if (addressComponents) {
            this.locality.setValue(this.searchSublocality(addressComponents));
            this.localityAuto.setValue(this.locality.value);
            this.landmark.setValue(this.searchLandmark(addressComponents));
            this.addressLine1.setValue(this.searchStreet(addressComponents));
            this.pincode.setValue(this.searchPin(addressComponents));
          }
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  searchSublocality(components: any): string {
    let sublocality;
    sublocality = components.find(comp => comp.types.indexOf('sublocality_level_1') !== -1);
    if (sublocality) {
      return sublocality.long_name;
    }
    sublocality = components.find(comp => comp.types.indexOf('sublocality_level_2') !== -1);
    if (sublocality) {
      return sublocality.long_name;
    }
    sublocality = components.find(comp => comp.types.indexOf('sublocality') !== -1);
    if (sublocality) {
      return sublocality.long_name;
    }
    sublocality = components.find(comp => comp.types.indexOf('locality') !== -1);
    if (sublocality) {
      return sublocality.long_name;
    }
    return '';
  }

  searchLandmark(components: any): string {
    let landmark = components.find(comp => comp.types.indexOf('landmark') !== -1);
    if (landmark) {
      return landmark.long_name;
    }
    landmark = components.find(comp => comp.types.indexOf('neighborhood') !== -1);
    if (landmark) {
      return landmark.long_name;
    }
    landmark = components.find(comp => comp.types.indexOf('route') !== -1);
    if (landmark) {
      return landmark.long_name;
    }
    return '';
  }

  searchStreet(components: any): string {
    let street = components.find(comp => comp.types.indexOf('premise') !== -1);
    if (street) {
      return street.long_name;
    }
    street = components.find(comp => comp.types.indexOf('street_number') !== -1);
    if (street) {
      return street.long_name;
    }
    return '';
  }

  searchPin(components: any): string {
    let pin = components.find(comp => comp.types.indexOf('postal_code') !== -1);
    if (pin) {
      return pin.long_name;
    }
    return '';
  }

  selectCountry(countryValue: any): void {
    const country = this.countryList.find((con) => con.codeId === countryValue);
    if (country) {
      this.selectedCountry = country;
      this.lat = country.latitude;
      this.long = country.longitude;
      // this.geoCoder = {};
      this.prepareCityAutocomplete();
      this.lookupModel.setPropertyCountry(this.selectedCountry);
    }
  }

  submit(value: any): void {
    if (this.locationForm.invalid || this.city.value !== this.cityAuto.value || this.locality.value !== this.localityAuto.value) {
      if (this.city.value !== this.cityAuto.value) {
        this.city.setErrors({ required: true });
      }
      if (this.locality.value !== this.localityAuto.value) {
        this.locality.setErrors({ required: true });
      }
      return;
    }
    if (this.selectedProperty && this.checkReadOnly()) {
      // Lookup model value should not be changed.
    }
    else if (this.selectedProperty) {
      this.selectedProperty.countryId = this.country.value;
      this.selectedProperty.countryName = this.selectedCountry.countryName;
      this.selectedProperty.city = value.city;
      this.selectedProperty.locality = value.locality;
      this.selectedProperty.area = value.locality;
      this.selectedProperty.addressLine1 = value.addressLine1;
      this.selectedProperty.landmark = value.landmark;
      this.selectedProperty.pincode = value.pincode;
      this.selectedProperty.lattitude = this.lat;
      this.selectedProperty.longitude = this.long;
      this.selectedProperty.cityBoundries = this.cityBounds;
      this.lookupModel.setVendorProperty(this.selectedProperty);
    } else {
      value.countryId = this.country.value;
      value.countryName = this.selectedCountry.countryName;
      value.area = value.locality;
      value.lattitude = this.lat;
      value.longitude = this.long;
      value.cityBoundries = this.cityBounds;
      this.lookupModel.setVendorProperty(value);
    }
    this.lookupModel.setPropertyCountry(this.selectedCountry);
    // this.lookupModel.setLocStatus(1);
    // this.lookupModel.setLocationChangeStatus(2);
    this.emitFormChanges(false);
    this.showNextPage.emit(2)
  }

  emitFormChanges(val: boolean): void {
    if(this.isFormDirty !== val) {
      this.isFormDirty = val;
      this.formChange.emit(val);
    }
  }

  get country() { return this.locationForm.get('country'); }
  get city() { return this.locationForm.get('city'); }
  get cityAuto() { return this.locationForm.get('cityAuto'); }
  get locality() { return this.locationForm.get('locality'); }
  get localityAuto() { return this.locationForm.get('localityAuto'); }
  get addressLine1() { return this.locationForm.get('addressLine1'); }
  get landmark() { return this.locationForm.get('landmark'); }
  get landmarkAuto() { return this.locationForm.get('landmarkAuto'); }
  get pincode() { return this.locationForm.get('pincode'); }
}
