import { Component, OnInit } from '@angular/core';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';
@Component({
  selector: 'app-myview-leads',
  templateUrl: './myview-leads.component.html',
  styleUrls: ['./myview-leads.component.css']
})
export class MyviewLeadsComponent implements OnInit {
  moduleList: Array<any> = [];
  getMylead: Array<any> = [];
  constructor(
    private rsbService: RsbService,
    private rsbLookup: RsbLookupModel
  ) {
    console.log("mylead")
  }

  ngOnInit(): void {
    console.log("my lead")
    console.log(JSON.stringify(this.getMylead))
    console.log(this.rsbLookup.getLoggedUser())
    this.loadModuleList();
    setTimeout((obj) => {
      this.getMyleads();
    }, 1000)

  }

  getMyleads() {
    const selectedMainModuleId: Array<any> = this.moduleList.filter((obj) => {
      return obj.selected && obj.selected === true;
    })
    if (!selectedMainModuleId[0].moduleName.toUpperCase().includes('PG')) {
      this.rsbService.myViewLeads({ "mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMylead = rsp.contents;
        } else {
          this.getMylead = []
        }
      })
    } else {
      this.rsbService.myViewLeadsForPg({ "mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMylead = rsp.contents;
        } else {
          this.getMylead = []
        }
      },(err) => {
        this.getMylead = []
      })
    }

  }

  changeMainModule(module): void {

    this.moduleList.forEach((obj) => {
      if (module.mainModuleId === obj.mainModuleId) {
        obj.selected = true;
      } else {
        obj.selected = false;
      }
    })

    this.getMyleads();
  }

  loadModuleList(): void {
    const vendorId = this.rsbLookup.getLoggedUser().vendorTypeId;

    this.rsbService.getAllMainModules({}).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        if (rsp.contents.length > 0) {

          this.moduleList = rsp.contents;
          this.moduleList = this.moduleList.filter((data) => {

            return data.vendorTypeId.includes(vendorId)
          })
          this.moduleList.forEach((obj) => {
            obj["selected"] = false
          })
          this.moduleList[0].selected = true;
          console.log(this.moduleList[0]);
        }
        else {
          this.moduleList = [];
        }
      }
    })


  }

}
