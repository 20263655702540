<ul class="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar">
    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center my-3" href="dashboard.html">
        <div class="sidebar-brand-icon">
            <img src="assets/img/apple-touch-icon.png" alt="">
        </div>
        <div class="sidebar-brand-text">
            <img src="assets/img/logo.png" alt="log" class="img-fluid">
        </div>
    </a>

    <ng-container *ngFor="let menu of menuOptions">
        
            <li class="nav-item" [ngClass]="{'active': menu.active}">
                <a  class="nav-link">
                    <i class={{menu.sideMenuicon}}></i>
                    {{menu.label}}
                    <!-- <span *ngIf="menu.submenuList"></span> -->
                </a>
            </li>
        
    </ng-container>

    <!-- <li class="nav-item" *ngFor="let menu of menuOptions">
        <a class="nav-link">
            <i class="ri-dashboard-fill"></i>
            <span> {{menu.label}}</span>
        </a>
          
    </li> -->
    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle" (click)="home()">
            <i class="ri-arrow-left-s-line"></i>
        </button>
    </div>
</ul>