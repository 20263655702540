import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RouterExtService } from 'src/app/common/services/router-ext.service';
import { RsbService } from 'src/app/service/rsb.service';
import { UploadProgressComponent } from 'src/app/shared/components/upload-progress/upload-progress.component';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  @ViewChild('inputRef') inputElement!: ElementRef;
  @ViewChild('rentInputRef') rentInputElement!: ElementRef;
  @ViewChild('clientInputRef') clientInputElement!: ElementRef;
  selectedProperty: any;
  private cityText: ElementRef;
  @ViewChild('cityText') set cityEle(cityElement: ElementRef) {
    if (cityElement && this.lookupModel.isBrowser) {
      this.cityText = cityElement;
      this.prepareCityAutocomplete();
    }
  }
  private localityText: ElementRef;
  @ViewChild('localityText') set localityEle(lclElement: ElementRef) {
    if (lclElement) {
      this.localityText = lclElement;
    }
  }
  private landmarkText: ElementRef;

  profileForm: FormGroup;
  soldPropertyValue: number = 0;
  rentPropertyValue: number = 0
  clientPropertyValue: number = 0;
  propertyImages = '';
  profile:any = {};
  awsFile: FileList;
  name:String = '';
  agency:String  = '';
  reraNumber:String  = '';
  operatingSince:String = '';
  private geoCoder;
  cityAutoComplete: any;
  selectedCountry: any;
  mapRestriction: any;
  cityBounds: any;
  localityAutoComplete: any;
  pincode:any;






  
 
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private lookupModel: RsbLookupModel,
    private rsbService: RsbService,
    private modalService: NgbModal,
    private routerExtService: RouterExtService,
    private router: Router
  ) { 
    

  }

  ngOnInit(): void {
    // this.selectedProperty = this.lookupModel.getVendorProperty();
    this.profileForm = this.fb.group({
      city: [ '', [Validators.required]],
      cityAuto: ['', [Validators.required]],

    })
    console.log("profile")
    //alert("gettting");
    this.getProfessionalDetails()
    const profileDetails = this.lookupModel.getLoggedUser();
    this.propertyImages = profileDetails.personalDetails.profilePic;


    
   
  }
  prepareCityAutocomplete(): void {
    if(this.checkReadOnly()) {
      return;
    }
    this.city.enable();
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.cityAutoComplete = new google.maps.places.Autocomplete(this.cityText.nativeElement, {
      
        types: ['(cities)'],
        //bounds: []
        strictBounds: true
      });
      this.cityAutoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = this.cityAutoComplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.mapRestriction = {
            latLngBounds: place.geometry.viewport,
            strictBounds: true
          };
          this.cityBounds = place.geometry.viewport;
          this.city.setValue(place.name);
          this.cityAuto.setValue(place.name);
          // this.locality.reset();
          // this.localityAuto.reset();
          // this.pincode.reset();
          const postalCode = place.address_components.find(comp => comp.types.indexOf('postal_code') !== -1);
          if (postalCode) {
            this.pincode.setValue(postalCode.long_name);
          }
          this.prepareLocalityAutocomplete();
        });
      });
    });
  }
  prepareLocalityAutocomplete(): void {
    if(this.checkReadOnly()) {
      this.locality.setValue(this.selectedProperty.area);
      this.localityAuto.setValue(this.selectedProperty.area);
      return;
    }
    if(this.selectedCountry.twoDigitCountryCode && this.cityBounds) {
      this.locality.enable();
      if (!this.localityAutoComplete) {
        this.localityAutoComplete = new google.maps.places.Autocomplete(this.localityText.nativeElement, {
          componentRestrictions: {
            country: this.selectedCountry.twoDigitCountryCode
          },
          bounds: this.cityBounds,
          strictBounds: true
        });
        this.localityAutoComplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = this.localityAutoComplete.getPlace();

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            this.mapRestriction = {
              latLngBounds: place.geometry.viewport,
              strictBounds: true
            };

            const postalCode = place.address_components.find(comp => comp.types.indexOf('postal_code') !== -1);
            if (postalCode) {
              this.pincode.setValue(postalCode.long_name);
            }
            this.locality.setValue(place.name);
            this.localityAuto.setValue(place.name);
          });
        });
      }
      else {
        this.localityAutoComplete.bounds = this.cityBounds;
      }
    }
  }
  checkReadOnly(): boolean {
    const readonly: boolean = this.selectedProperty && ([1, 2, 3].includes(this.selectedProperty.status));
    return readonly;
  }

  
loadDetails() {
 
  // this.profileForm = this.fb.group({
  //   name: [this.lookupModel.getLoggedUser().personalDetails.firstName ? this.lookupModel.getLoggedUser().personalDetails.firstName : '', [Validators.required]],
  //   agency: [this.profile && this.profile.agency ? this.profile.agency : '', [Validators.required]],
  //   reraNumber: [this.profile &&  this.profile.reraNumber ? this.profile.reraNumber:'', [Validators.required]],
    
  //   operatingSince: [this.profile && this.profile.operatingSince ? this.profile.operatingSince : '', [Validators.required]],
    
  //   soldProp: [this.profile && this.profile.soldProp ? this.profile.soldProp : '', [Validators.required]],
  //   rentProp: [this.profile && this.profile.reraNumber ? this.profile.reraNumber : '', [Validators.required]],
  //   clientsServed: [this.profile && this.profile.clientsServed ? this.profile.clientsServed : '', [Validators.required]],
  // });
}
  ngAfterContentInit() : void {
   
    
  }
  getProfessionalDetails(){
    //alert("getting here1");
    this.rsbService.getProfessionalDetails({}).subscribe((details:any)=>{
      console.log("getting detailes",details);
      this.profile= details.contents.professionalDetails? details.contents.professionalDetails:{};
     // alert("this profile is"+JSON.stringify(this.profile));
      this.agency = this.profile.agency;
      this.soldPropertyValue = this.profile.soldProperties ? this.profile.soldProperties : 0;
      this.rentPropertyValue  = this.profile.rentProperties ? this.profile.rentProperties : 0;
      this.clientPropertyValue = this.profile.clientServed ? this.profile.clientServed  : 0;
      this.name = this.lookupModel.getLoggedUser().personalDetails.firstName;
      this.reraNumber = this.profile.reraNumber;
      this.operatingSince = this.profile.operatingSince;
      

      this.loadDetails();

    })
  }

  addSold() {

    if (this.inputElement) {
      let inputValue = this.inputElement.nativeElement.value;
      this.soldPropertyValue = inputValue++;

    }
    this.soldPropertyValue++;
    this.profileForm.get('soldProp')?.setValue(this.soldPropertyValue);

  }
  subtractSold() {
    if (this.inputElement) {
      let inputValue = this.inputElement.nativeElement.value;
      if (inputValue > 0) {
        this.soldPropertyValue = inputValue - 1;
      }
    }
    this.profileForm.get('soldProp')?.setValue(this.soldPropertyValue);

  }


  addRent() {
    if (this.rentInputElement) {
      let inputValue = this.rentInputElement.nativeElement.value;
      this.rentPropertyValue = inputValue++;
    }
    this.rentPropertyValue++;
    this.profileForm.get('rentProp')?.setValue(this.rentPropertyValue);

  }

  subtractRent() {
    if (this.rentInputElement) {
      let inputValue = this.rentInputElement.nativeElement.value;
      if (inputValue > 0) {
        this.rentPropertyValue = inputValue - 1;
      }

    }
    this.profileForm.get('rentProp')?.setValue(this.rentPropertyValue);


  }


  addClients() {
    if (this.inputElement) {
      let inputValue = this.clientInputElement.nativeElement.value;
      this.clientPropertyValue = inputValue++;
    }
    this.clientPropertyValue++;
    this.profileForm.get('clientsServed')?.setValue(this.clientPropertyValue);

  }

  subtractClients() {
    if (this.clientInputElement) {
      let inputValue = this.clientInputElement.nativeElement.value;
      if (inputValue > 0) {
        this.clientPropertyValue = inputValue - 1;
      }

    }
    this.profileForm.get('clientsServed')?.setValue(this.clientPropertyValue);

  }
  isValid(): boolean {
    return !!this.name && !!this.agency && !!this.reraNumber && !!this.operatingSince && !!this.city && !!this.pincode;
  }
  save() {
    if (this.isValid()) {

    const args = {
      name: this.name,
      agency: this.agency,
      reraNumber: this.reraNumber,
      operatingSince:this.operatingSince,
      soldProperties:this.soldPropertyValue,
      rentProperties:this.rentPropertyValue,
      clientServed:this.clientPropertyValue,
      profilePic:this.propertyImages,
      city:this.city.value,
      pincode:this.pincode
    };
    this.rsbService.agentProfile(args).subscribe((rsp) => {})
  }


  }
  uploadAws(event: any, fieldName?: string): void {
    this.awsFile = event.target.files;
    const file = this.awsFile.item(0);
    const modalRef = this.modalService.open(UploadProgressComponent, { windowClass: 'signInForm' });
    modalRef.componentInstance.file = file;
    modalRef.result.then((path: string) => {
      if (path) {
        this.propertyImages = path;
      }
    
    });
  }
  get city() { return this.profileForm.get('city'); }
  get cityAuto() { return this.profileForm.get('cityAuto'); }
  get locality() { return this.profileForm.get('locality'); }
  get localityAuto() { return this.profileForm.get('localityAuto'); }

  // get name() { return this.profileForm.get('name'); }
  // get agency() { return this.profileForm.get('agency'); }
  // get reraNumber() { return this.profileForm.get('reraNumber'); }
  // get operatingSince() { return this.profileForm.get('operatingSince'); }
  // get soldProp() { return this.profileForm.get('soldProp'); }
  // get rentProp() { return this.profileForm.get('rentProp')}
  // get clientsServed() { return this.profileForm.get('clientsServed')}




}





