<div class="table-responsive">
    <table class="table table-hover">
    <thead class="">
        <tr>
        <th scope="col"><input type="checkbox"></th>
        <th scope="col">Property Id</th>
        <th scope="col">Property Name</th>
        <th scope="col">Date Added</th>                           
    </tr>
    </thead>
    <tbody>
        <tr *ngFor="let listings of allListings; let i =index">
        <td><input type="checkbox"></td>
        <td>{{listings.listingId}}</td>
        <td>{{listings.propertyTitle}}</td>
        <td>{{listings.createdAt}}</td>
        <!-- <td><button class="btn btn-success btn-sm">Edit</button></td> -->
        </tr>
    
    </tbody>
    </table>
</div>