import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RsbLookupModel } from 'src/app/common/model/rsb-lookup.model';
import { RsbService } from 'src/app/service/rsb.service';

@Component({
  selector: 'app-vendor-dashboard',
  templateUrl: './vendor-dashboard.component.html',
  styleUrls: ['./vendor-dashboard.component.css']
})
export class VendorDashboardComponent implements OnInit {
  moduleList: Array<any> = [];

  getMylead: any;
  getMyViewLead: any;
  getMyLeadsData: boolean = true;
  isviewLeads: boolean = false;
  countData:any;

  constructor(
    private rsbService: RsbService,
    private rsbLookup: RsbLookupModel,
    private route: Router) { }
  Postproperty(route: any) {
    this.route.navigate([route]);
  }
  Applyloan(route: any) {
    this.route.navigate([route]);
  }

  ngOnInit(): void {
    console.log("my lead")
    console.log(JSON.stringify(this.getMylead))
    console.log(this.rsbLookup.getLoggedUser())
   this.getDashboardCount()
     this.loadModuleList();

    setTimeout((obj) => {
      this.getMyleads();
    }, 1000)
    //this.getMyViewLeads();
  }
  // getMyleads() {
  //   this.rsbService.myLeads().subscribe((rsp) => {
  //     this.getMylead = rsp.contents;


  //   })

  // }
  getMyleads() {
   // alert("my leads"+this.moduleList.length);
    const selectedMainModuleId: Array<any> = this.moduleList.filter((obj) => {
     // alert(obj.selected);
      return obj.selected && obj.selected === true;
    })
    console.log("selectedMain moduleee is",selectedMainModuleId[0]);

    if (!selectedMainModuleId[0].moduleName.toUpperCase().includes('PG')) {
      this.rsbService.myLeads({ "mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMylead = rsp.contents;
        } else {
          this.getMylead = []
        }
      })
    } else {
      this.rsbService.myLeadsForPg({ "mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMylead = rsp.contents;
        } else {
          this.getMylead = []
        }
      }, (err) => {
        this.getMylead = []
      })
    }

  }
  changeMainModule(module:String): void {
    //alert(JSON.stringify(module));
    this.moduleList.forEach((obj) => {
      if (module === obj.mainModuleId) {
       // alert("here");
        obj["selected"] = true;
      } else {
        obj["selected"] = false;
      }
    })
   
   if(this.isviewLeads) {
    this.getMyViewLeads();
   } else {
   // alert("getting here");
    this.getMyleads();
   }
  }
  getMyViewLeads() {
    const selectedMainModuleId: Array<any> = this.moduleList.filter((obj) => {
      return obj.selected && obj.selected === true;
    })
    console.log("selectedMain module is",selectedMainModuleId);
    if (!selectedMainModuleId[0].moduleName.toUpperCase().includes('PG')) {
      this.rsbService.myViewLeads({ "mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        let listings = rsp.contents[0];
        this.rsbLookup.setlistingId(listings.listingId)
        // listings.forEach((listingid)=>{
        //   this.rsbLookup.setlistingId(listingid.listingId)
        // })
        // alert(JSON.stringify(rsp.contents))
        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMyViewLead = rsp.contents;
        } else {
          this.getMyViewLead = []
        }
      })
    }
    else {
      this.rsbService.myViewLeadsForPg({"mainModuleId": selectedMainModuleId && selectedMainModuleId.length ? selectedMainModuleId[0].mainModuleId : '' }).subscribe((rsp) => {
        if (rsp.statusCode === 0 && rsp.contents) {
          this.getMylead = rsp.contents;
        } else {
          this.getMylead = []
        }
      }, (err) => {
        this.getMylead = []
      })
    }
  }

  loadModuleList(): void {
    const vendorId = this.rsbLookup.getLoggedUser().vendorTypeId;

    this.rsbService.getAllMainModules({}).subscribe((rsp) => {
      if (rsp.statusCode === 0) {
        if (rsp.contents.length > 0) {

          this.moduleList = rsp.contents;
          this.moduleList = this.moduleList.filter((data) => {

            return data.vendorTypeId.includes(vendorId)
          })
          this.moduleList.forEach((obj) => {
            obj["selected"] = false
          })
          this.moduleList[0].selected = true;
          console.log(this.moduleList[0]);
        }
        else {
          this.moduleList = [];
        }
      }
    })


  }
  getDashboardCount(){
    this.rsbService.getDashboardCount({}).subscribe((data)=>{
      this.countData=data;

    });
  }
  allLeads() {
    this.getMyLeadsData = true;
    this.isviewLeads = false;
    this.getMyleads();
  }
  viewLeads() {
    this.getMyLeadsData = false;
    this.isviewLeads = true;
    this.getMyViewLeads();

  }


}
